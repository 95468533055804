<template>
	<v-row>
		<v-dialog v-model="dialog" max-width="900px" scrollable>
			<template #activator="{ on, attrs }">
				<v-btn v-bind="attrs" depressed class="yellow--bg border-shaped" v-on="on" v-if="addr">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn large v-bind="attrs" elevation="0" class="mb-8 ml-3 btn-add border-shaped" v-on="on" v-else>
					<v-icon>mdi-plus</v-icon>
					{{ $t('address.add') }}
				</v-btn>
			</template>
			<v-form ref="form" v-model="valid" @submit.prevent="addr ? updateAddress() : createAddress()">
				<v-card shaped elevation="24">
					<v-card-title>
						<span class="text-h4 text-lg-h3 font-weight-bold">{{ addr ? $t('edit_address') : $t('create_address') }}</span>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col>
									<v-select
										v-model="address.type"
										:label="$t('address.type')"
										outlined
										class="border-shaped"
										return-object
										:items="address_types_items"
										:item-text="(item) => ($i18n.locale === 'fr' ? item.name : item.en_name)"
										required
										:rules="addressTypeRules"
									></v-select>
									<div class="gap-2 d-flex">
										<v-text-field
											v-model="address.contact"
											:label="$t('address.contact')"
											required
											prepend-inner-icon="mdi-account"
											:rules="contactRules"
											outlined
											class="border-shaped"
										></v-text-field>
										<v-text-field
											v-if="address.type?.id > 1"
											v-model="address.raison_sociale"
											:label="$t('address.raison_sociale')"
											:rules="raisonSocialeRules"
											prepend-inner-icon="mdi-domain"
											outlined
											class="border-shaped"
										></v-text-field>
									</div>
									<v-text-field
										v-model="address.email"
										:label="$t('global.email')"
										required
										prepend-inner-icon="mdi-at"
										:rules="emailRules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.address"
										:label="$t('address.address')"
										required
										prepend-inner-icon="mdi-map-marker"
										:rules="addressRules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.complement"
										:label="$t('address.complement')"
										prepend-inner-icon="mdi-comment-text-outline"
										outlined
										class="border-shaped"
									></v-text-field>
									<div class="gap-2 d-flex">
										<v-text-field
											v-model="address.code_postal"
											:label="$t('address.postal_code')"
											required
											prepend-inner-icon="mdi-email-outline"
											:rules="codePostalRules"
											outlined
											class="border-shaped"
										></v-text-field>
										<v-text-field
											v-model="address.city"
											:label="$t('address.city')"
											required
											prepend-inner-icon="mdi-city"
											:rules="cityRules"
											outlined
											class="border-shaped"
										></v-text-field>
									</div>
									<v-select
										v-model="address.country"
										:items="countries"
										item-text="name"
										item-value="iso"
										:label="$t('address.country')"
										return-object
										outlined
										class="border-shaped"
									>
										<template #selection="{ item }">
											<v-list-item-icon>
												<CountryFlag :country="item.iso" rounded class="mt-n2" />
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ $i18n.locale === 'fr' ? item.name : item.en_name }}</v-list-item-title>
											</v-list-item-content>
										</template>
										<template #item="{ item }">
											<v-list-item-icon>
												<CountryFlag :country="item.iso" rounded class="mt-n2" />
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ $i18n.locale === 'fr' ? item.name : item.en_name }}</v-list-item-title>
											</v-list-item-content>
										</template>
									</v-select>
									<vue-tel-input-vuetify
										v-model="address.phone_mobile"
										:label="$t('address.phone.mobile')"
										:preferred-countries="['FR', 'BE', 'CH', 'CA']"
										outlined
										class="border-shaped-tel"
										mode="international"
										:rules="phoneMobileRules"
									></vue-tel-input-vuetify>
									<vue-tel-input-vuetify
										v-model="address.phone_fix"
										:label="$t('address.phone.fix')"
										:preferred-countries="['FR', 'BE', 'CH', 'CA']"
										outlined
										class="border-shaped-tel"
										mode="international"
										:rules="phoneFixRules"
									></vue-tel-input-vuetify>
									<v-textarea v-model="address.comment" :label="$t('address.comment')" outlined counter></v-textarea>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn type="submit" class="border-shaped yellow--bg" depressed large :disabled="!valid" :loading="loading">
							{{ $t('form.submit') }}
						</v-btn>
					</v-card-actions>
					<v-overlay :absolute="false" :value="loading">
						<v-progress-circular indeterminate size="64"></v-progress-circular>
					</v-overlay>
				</v-card>
			</v-form>
		</v-dialog>
	</v-row>
</template>

<script lang="js">
	import axios from 'axios';
	import { EventBus } from '@/utils';
	import isPostalCode from 'validator/lib/isPostalCode';
	import isEmail from 'validator/lib/isEmail';

	export default {
		name: 'AddressForm',
		props: ['addr'],
		data() {
			return {
				loading: false,
				valid: false,
				dialog: false,
				address: {
					type: this.addr ? this.addr.address_type : null,
					contact: this.addr ? this.addr.contact : '',
					raison_sociale: this.addr ? this.addr.raison_sociale : '',
					email: this.addr ? this.addr.email : '',
					address: this.addr ? this.addr.address : '',
					complement: this.addr ? this.addr.complement : '',
					code_postal: this.addr ? this.addr.code_postal : '',
					city: this.addr ? this.addr.city : '',
					country: this.addr ? this.addr.country : { iso: 'FR' },
					phone_mobile: this.addr ? this.addr.phone_mobile : '',
					phone_fix: this.addr ? this.addr.phone_fix : '',
					comment: this.addr ? this.addr.comment : '',
				},
				countries: [],
				address_types_items: [],
			};
		},
		computed: {
			contactRules() {
				return [this.address.contact ? true : this.$t('form.required.address.contact')];
			},
			raisonSocialeRules() {
				return [this.address.raison_sociale ? true : this.$t('form.required.address.raison_sociale')];
			},
			emailRules() {
				return [
					this.address.email ? true : this.$t('form.required.address.email'),
					(this.address.email ? isEmail(this.address.email) : true) || this.$t('form.invalid.email'),
				];
			},
			addressRules() {
				return [this.address.address ? true : this.$t('form.required.address.address')];
			},
			codePostalRules() {
				return [
					this.address.code_postal ? true : this.$t('form.required.address.postal_code'),
					(this.address.code_postal ? isPostalCode(this.address.code_postal, this.address.country.iso) : true) || this.$t('form.invalid.address.postal_code'),
				];
			},
			cityRules() {
				return [this.address.city ? true : this.$t('form.required.address.city')];
			},
			commentRules() {
				return [true];
			},
			phoneMobileRules() {
				return [
					!!this.address.phone_mobile || !!this.address.phone_fix || this.$t('form.required.address.phone'),
					this.address.phone_mobile ? /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}([-.\s]?\d{1,4}){1,3}$/.test(this.address.phone_mobile) : true || this.$t('form.invalid.address.phone.mobile'),
				];
			},
			phoneFixRules() {
				return [
					!!this.address.phone_mobile || !!this.address.phone_fix || this.$t('form.required.address.phone'),
					this.address.phone_fix ? /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}([-.\s]?\d{1,4}){1,3}$/.test(this.address.phone_fix) : true || this.$t('form.invalid.address.phone.fix'),
				];
			},
			addressTypeRules() {
				return [this.address.type ? true : this.$t('form.required.address.type')];
			}
		},
		mounted() {
			this.getCountries();
			this.getAddressType();
		},
		methods: {
			validPhoneMobile(value) {
				this.isPhoneMobileValid = value.isValid;
			},
			validPhoneFix(value) {
				this.isPhoneFixValid = value.isValid;
			},
			getCountries() {
				axios
					.get(process.env.VUE_APP_API_URL + '/country', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((response) => {
						this.countries = response.data.countries;
					});
			},
			getAddressType() {
				axios
					.get(process.env.VUE_APP_API_URL + '/address_type', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.address_types_items = r.data.address_types;
						!this.address.type ? (this.address.type = this.address_types_items[0]) : null;
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
			createAddress() {
				if (this.$refs.form.validate()) {
					this.loading = true;
					const data = {
						address: this.address.address,
						code_postal: this.address.code_postal,
						city: this.address.city,
						complement: this.address.complement,
						raison_sociale: this.address.raison_sociale,
						contact: this.address.contact,
						email: this.address.email,
						phone_mobile: this.address.phone_mobile,
						phone_fix: this.address.phone_fix,
						address_type: this.address.type.id,
						comment: this.address.comment,
						country: this.address.country.iso,
					};
					axios
						.post(process.env.VUE_APP_API_URL + '/address', data, {
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						})
						.then(() => {
							this.dialog = false;
							EventBus.$emit('refreshAddresses');
							EventBus.$emit('notif', this.$t('created'), 'success');
							this.$refs.form.reset();
						})
						.catch(() => {
							EventBus.$emit('notif', this.$t('global.error'), 'error');
						})
						.finally(() => {
							this.loading = false;
						});
				}
			},
			updateAddress() {
				console.log(this.$refs.form.validate());

				if (this.$refs.form.validate()) {
					this.loading = true;
					const data = {
						address: this.address.address,
						code_postal: this.address.code_postal,
						city: this.address.city,
						complement: this.address.complement,
						raison_sociale: this.address.raison_sociale,
						contact: this.address.contact,
						email: this.address.email,
						phone_mobile: this.address.phone_mobile,
						phone_fix: this.address.phone_fix,
						address_type: this.address.type.id,
						comment: this.address.comment,
						country: this.address.country.iso,
					};
					axios
						.put(process.env.VUE_APP_API_URL + '/address/' + this.addr.id, data, {
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						})
						.then(() => {
							this.loading = false;
							this.dialog = false;
							EventBus.$emit('refreshAddresses');
							EventBus.$emit('notif', this.$t('updated'), 'success');
							this.$refs.form.reset();
						})
						.catch(() => {
							EventBus.$emit('notif', this.$t('global.error'), 'error');
						})
						.finally(() => {
							this.loading = false;
						});
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.btn-add {
		background-color: var(--color-yellow) !important;
	}
</style>

<i18n lang="json">
{
	"fr": {
		"created": "Adresse créée",
		"updated": "Adresse modifiée",
		"create_address": "Créer une adresse",
		"edit_address": "Modifier l'adresse"
	},
	"en": {
		"created": "Address created",
		"updated": "Address updated",
		"create_address": "Create address",
		"edit_address": "Edit address"
	}
}
</i18n>
